import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
//import { PATHOGEN_LABELS2 } from '../../utils/const'
import Table from '../../components/Table'
import { format } from 'd3-format'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { group } from 'd3-array'
import { PATHOGEN_LABELS_API } from '../../utils/const'

const IG11Table = ({ regions }) => {
  const { t } = useTranslation()
  const formatThous = format(',.0f')

  // const allYearsWithData = []
  // const wrangleData = () => {
  //   const allCategories = []

  //   const allData = Object.entries(regions)
  //     .map(([key, value]) => {
  //       const year = +key.slice(-4)
  //       //gets the years that have data so we know what columns to make
  //       if (key.startsWith('total') && value !== null) {
  //         allYearsWithData.push(year)
  //       }
  //       const keysToExclude = [
  //         'memberState',
  //         'iSO3',
  //         'id',
  //         'incomeGroup',
  //         'uNICEFRegion'
  //       ]
  //       if (!keysToExclude.includes(key)) {
  //         const pathogen = key.split('A')[0]
  //         allCategories.push(pathogen)
  //         return { year, pathogen, value }
  //       } else return null
  //     })
  //     .filter((item) => item)

  //   const categories = Array.from(new Set(allCategories))

  //   const formattedData = categories.map((category) => {
  //     const catData = allData
  //       .filter((d) => d.pathogen === category)
  //       .reduce((acc, d) => {
  //         acc[d.year] =
  //           d.value === -2
  //             ? t('no data')
  //             : d.value === -1
  //             ? t('not measured')
  //             : d.value === null
  //             ? ''
  //             : formatThous(d.value)
  //         return acc
  //       }, {})

  //     return Object.assign(
  //       {
  //         pathogen: t(PATHOGEN_LABELS2[category])
  //         //pathogen: category
  //       },
  //       catData
  //     )
  //   })

  //   return formattedData
  // }
  const BASELINE_YEAR = 2019
  const dataMap = group(regions, (d) => d.DIMENSION2)
  const groupedByPathogen = Array.from(dataMap.values())
  const formattedData = groupedByPathogen
    .map((item) => {
      return item.reduce((acc, d) => {
        acc['pathogen'] =
          d.DIMENSION2 === null
            ? t('Total')
            : t(PATHOGEN_LABELS_API[d.DIMENSION2])
        acc[d.YEAR === 2019 ? `${t('Baseline')} (${BASELINE_YEAR})` : d.YEAR] =
          formatThous(d.VALUE_NUM)
        return acc
      }, {})
    })
    .sort((a, b) =>
      a.pathogen.toLowerCase() > b.pathogen.toLowerCase() ? 1 : -1
    )
    .sort((a, b) => (a.pathogen === t('Total') ? -1 : 1))

  const years = Object.keys(formattedData[0])
    .filter((item) => item !== 'pathogen')
    .sort((a, b) => (a > b ? 1 : -1))
    .sort((a, b) => (a === `${t('Baseline')} (${BASELINE_YEAR})` ? -1 : 1))

  formattedData.forEach((c) => {
    years.forEach((y) => {
      return c.hasOwnProperty(y) ? c : (c[y] = t('not measured'))
    })
  })

  //const ig11Data = wrangleData()
  //console.log('ig11Data in table', ig11Data)

  const data = React.useMemo(() => formattedData, [formattedData])
  // const baseHeader = [
  //   {
  //     Header: 'Pathogen',
  //     accessor: 'pathogen' // accessor is the "key" in the data
  //   }
  // ]

  const yearHeaders = years.map((year) => {
    return {
      Header: `${year}`,
      accessor: `${year}`
    }
  })

  const columns = React.useMemo(() => {
    const baseHeader = [
      {
        Header: t('pathogen'),
        accessor: 'pathogen' // accessor is the "key" in the data
      }
      // {
      //   Header: t('Baseline'),
      //   accessor: 'Baseline' // accessor is the "key" in the data
      // }
    ]
    return baseHeader.concat(yearHeaders)
  }, [yearHeaders, t])
  return (
    <Table
      data={data}
      columns={columns}
      description={t(
        'A table that lists diseases and the number of deaths averted each year.'
      )}
    />
  )
}

export default IG11Table
