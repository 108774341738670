import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { scaleLinear, scaleBand } from 'd3-scale'
import { format } from 'd3-format'
import { stack } from 'd3-shape'
import { range, max } from 'd3-array'
import { useDimensionsContext } from '../ChartContext'
import { PATHOGEN_LABELS_API } from '../../utils/const'
import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { useMediaQuery } from 'react-responsive'

const StyledXAxis = styled.g`
  font-size: 1rem;
  fill: var(--darkGray);
  text-anchor: middle;
  }
  ${Mq.xl} {
    font-size: 0.75rem;
  }
`

const XAxis = ({ x, maxYear, regional }) => {
  const { t } = useTranslation()
  //const currYear = new Date().getFullYear()
  const xTicks = [2019, ...range(2021, 2032)].flat()
  const dimensions = useDimensionsContext()
  const matches = useMediaQuery({ query: '(max-width: 1080px)' }) //Lg
  return (
    <StyledXAxis
      className="axis"
      transform={`translate(0, ${dimensions.boundedHeight})`}
    >
      {xTicks.map((tick, i) => {
        return (
          <React.Fragment key={tick}>
            <g
              style={{
                transform: `translate(${x(tick) + x.bandwidth() / 2}px, 0)`
              }}
            >
              {tick === 2019 && matches ? (
                <text y={25} dy="0.71em" fontWeight="bold">
                  {`${t('BL')}*`}
                </text>
              ) : tick === 2019 && !matches ? (
                <>
                  <text y={25} dy="0.71em" fontWeight="bold">
                    {t('Base')}
                  </text>
                  <text y={47} dy="0.71em" fontWeight="bold">
                    {t('line')}
                  </text>
                </>
              ) : (
                <text
                  y={25}
                  dy="0.71em"
                  opacity={
                    tick <= maxYear || (tick === 2031 && !regional) ? 1 : 0
                  }
                >
                  {tick === 2031
                    ? t('global target')
                    : dimensions.width < 500
                    ? "'" + tick.toString().slice(-2)
                    : tick}
                </text>
              )}
            </g>
            {tick === 2019 && matches ? (
              <g
                style={{
                  transform: `translate(${x(tick)}px, 0)`
                }}
              >
                <text y={60} dy="0.71em" fontWeight="bold" textAnchor="start">
                  {`*${t('BL')} = ${t('Baseline')}`}
                </text>
              </g>
            ) : null}
          </React.Fragment>
        )
      })}
    </StyledXAxis>
  )
}

const StyledYAxis = styled.g`
  font-size: 1rem;
  fill: var(--darkGray);
  dominant-baseline: middle;
  text-anchor: start;
  .solid-line {
    stroke: var(--lightGray);
  }
  ${Mq.md} {
    font-size: 0.9rem;
  }
`

const YAxis = ({ y, regional, roundedMaxY }) => {
  const formatMill = format('.0s')
  const formatMill2 = format('.2s')

  const dimensions = useDimensionsContext()

  const makeTicks = (max) => {
    let ticks = []
    if (max < 3000000) {
      for (let i = 0; i <= max; i += 500000) {
        ticks.push(i)
      }
      return ticks
    } else return [0, 1000000, 2000000, 3000000, 4000000, 5000000, 6000000]
  }

  const yTicks = regional
    ? makeTicks(roundedMaxY)
    : [0, 1000000, 2000000, 3000000, 4000000, 5000000, 6000000]

  return (
    <StyledYAxis>
      {yTicks.map((tick, i) => {
        return (
          <g
            key={tick}
            style={{
              transform: `translate(0, ${y(tick)}px)`
            }}
          >
            <line className="solid-line" x2={dimensions.boundedWidth} />
            <text x={-30}>
              {regional ? formatMill2(tick) : formatMill(tick)}
            </text>
          </g>
        )
      })}
    </StyledYAxis>
  )
}

const TargetBar = styled.rect`
  fill-opacity: 0;
  stroke-width: 3px;
  stroke-dasharray: 5;
  stroke: var(--brightBlue);
`
const TargetLabel = styled.text`
  fill: var(--brightBlue);
  font-size: 1rem;
  font-weight: bold;
  text-anchor: middle;
`
const Total = styled.text`
  fill: var(--brightBlue);
  font-size: 0.8rem;
  font-weight: bold;
  text-anchor: middle;
  ${Mq.md} {
    font-size: 0.7rem;
  }
  ${Mq.xs} {
    font-size: 0.6rem;
  }
`
const Line = styled.line`
  stroke: var(--baseBlue);
  stroke-width: 3px;
`
const StyledG = styled.g`
  .hover {
    pointer-events: none;
  }
`
const IG11Chart = ({ formattedData, totals, regional, print }) => {
  const dimensions = useDimensionsContext()
  const [node, setNode] = useState()
  const [pathogen, setPathogen] = useState()
  const [value, setValue] = useState()
  const { t } = useTranslation()
  //const { categories } = formattedData
  const categories = Array.from(
    new Set(formattedData.map((d) => Object.keys(d)).flat())
  )
    .map((d) => (d !== 'year' ? d : null))
    .filter((d) => d)
    .sort((a, b) =>
      PATHOGEN_LABELS_API[a].toLowerCase() >
      PATHOGEN_LABELS_API[b].toLowerCase()
        ? 1
        : -1
    )
    .reverse()

  const stackMaker = stack().keys(categories)
  const stackData = stackMaker(formattedData)
  const x = scaleBand([2019, ...range(2021, 2032)].flat(), [
    20,
    dimensions.boundedWidth - 20
  ]).padding(0.2)
  //to make scale dynamic for regions
  const maxY = max(totals, (d) => d.VALUE_NUM)
  const roundedMaxY = Math.ceil(maxY / 1000000) * 1000000

  const y = scaleLinear(
    [0, regional ? roundedMaxY : 6000000],
    [dimensions.boundedHeight, 0]
  )

  const handleMouseEnter = (d, i, value) => {
    setPathogen(categories[i])
    setNode(d)
    setValue(value)
  }
  const handleMouseLeave = () => {
    setPathogen()
    setNode()
    setValue()
  }
  const formatMill3 = format('.3s')
  const formatMill2 = format('.2s')
  const totalFor2019 = totals.find((d) => d.YEAR === 2019).VALUE_NUM
  const maxYear = formattedData[formattedData.length - 1].year

  const COLORS = {
    YFEVER: 'var(--purple)',
    TUBERCULOSIS: 'var(--blue6)',
    TETANUS: 'var(--baseBlue)',
    PNEUMOCOCCUS: 'var(--blue7)',
    RUBELLA: 'var(--blue8)',
    ROTAVIRUS: 'var(--blue4)',
    PERTUSSIS: 'var(--blue5)',
    MENA: 'var(--aqua2)',
    MEASLES: 'var(--medGreen)',
    JAPENC: 'var(--lime)',
    HPV: 'var(--chartreuse)',
    HIB: 'var(--yellow2)',
    HEPATITISB: 'var(--orange2)',
    DIPHTHERIA: 'var(--orange)'
  }
  return (
    <StyledG>
      <XAxis x={x} maxYear={maxYear} regional={regional} />
      <YAxis y={y} regional={regional} roundedMaxY={roundedMaxY} />

      {/* Stacked Bars */}
      {stackData.map((category, i) => (
        <g key={i}>
          {category.map((d) => {
            const year = node ? node.data.year : null
            return (
              <React.Fragment key={`${d.data.year}-${categories[i]}`}>
                <rect
                  id={print ? null : `${d.data.year}-${categories[i]}`}
                  fill={COLORS[categories[i]]}
                  strokeWidth={
                    year === d.data.year && pathogen === categories[i]
                      ? '3px'
                      : '1px'
                  }
                  stroke={
                    year === d.data.year && pathogen === categories[i]
                      ? `var(--black)`
                      : `var(--white)`
                  }
                  x={x(d.data.year)}
                  y={y(d[1])}
                  height={y(d[0]) - y(d[1])}
                  width={x.bandwidth()}
                  onMouseOver={(e) => handleMouseEnter(d, i, d[1] - d[0])}
                  onMouseLeave={() => handleMouseLeave()}
                />
              </React.Fragment>
            )
          })}
        </g>
      ))}
      <use href={`#${node?.data.year}-${pathogen}`} className={'hover'} />
      {/* Totals labels at top of bars */}
      {totals.map((d) => {
        //console.log('d', d)
        return (
          <Total
            key={d.YEAR}
            x={x(d.YEAR) + x.bandwidth() / 2}
            y={y(d.VALUE_NUM) - 5}
          >
            {d.VALUE_NUM ? formatMill2(d.VALUE_NUM) : null}
          </Total>
        )
      })}
      {/* Target bar and label */}
      {regional ? null : (
        <>
          <g>
            <TargetBar
              x={x(2031)}
              y={y(5800000)}
              width={x.bandwidth()}
              height={y(6000000 - 5800000)}
            />
            <TargetLabel x={x(2031) + x.bandwidth() / 2} y={y(5900000)}>
              5.8M
            </TargetLabel>
          </g>
          {/* Arrow from first to last bar */}
          <defs>
            <marker
              id="arrowhead"
              markerWidth="7"
              markerHeight="4"
              refX="0"
              refY="2"
              orient="auto"
              fill={`var(--baseBlue)`}
            >
              <polygon points="0 0, 7 2, 0 4" />
            </marker>
          </defs>
          <Line
            x1={x(2019) + x.bandwidth() / 2}
            y1={y(totalFor2019) - y(6000000)}
            x2={x(2031)}
            y2={y(5750000)}
            markerEnd="url(#arrowhead)"
          />
          <circle
            cx={x(2019) + x.bandwidth() / 2}
            cy={y(totalFor2019) - y(6000000)}
            fill={`var(--baseBlue)`}
            r={8}
          />
        </>
      )}
      {/* tooltip */}
      <svg
        x={node ? x(node.data.year) + x.bandwidth() : 0}
        y={node ? y(node[1]) - 20 : 0}
      >
        <g opacity={value ? 1 : 0}>
          <rect
            x={15}
            y={0}
            width={220}
            height={135}
            rx={4}
            ry={4}
            fill={COLORS[pathogen]}
          />
          <rect
            x={21}
            y={0}
            width={20}
            height={20}
            style={{ transform: 'rotate(45deg)' }}
            fill={COLORS[pathogen]}
          />
          <text fontSize={20} fill="white">
            <tspan x={25} y={10} dy="1em" fontWeight={'bold'} fontSize={30}>
              {value < 1000000
                ? (value / 1000000).toFixed(1) + 'M'
                : formatMill3(value)}
            </tspan>
            <tspan x={25} y={45} dy="1em">
              {t('deaths averted due to')}
            </tspan>
            <tspan x={25} y={70} dy="1em">
              {t('immunization against')}
            </tspan>
            <tspan x={25} y={95} dy="1em" fontWeight={'bold'}>
              {t(PATHOGEN_LABELS_API[pathogen])}
            </tspan>
          </text>
        </g>
      </svg>
    </StyledG>
  )
}

export default IG11Chart
