import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import PageHeader from '../components/PageHeader'
//import Header from '../components/Header'
import { PageContent, ChartTitle } from '../styles/SharedStyles'
import IG11ChartWrapper from '../components/IG11/IG11ChartWrapper'
import IG11Table from '../components/IG11/IG11Table'
import Notes from '../components/Notes'
import Footer from '../components/Footer'
import {
  LOCATION_LABELS,
  INCOME_GROUPS,
  UNI_REGIONS,
  WHO_LOCATION_OPTIONS,
  INC_LOCATION_OPTIONS,
  UNI_LOCATION_OPTIONS,
  REGION_LOOKUP
} from '../utils/const'
import {
  useQueryParamString,
  getQueryParams
} from 'react-use-query-param-string'
import SelectRegion from '../components/SelectRegion'
import RegionInfo from '../components/RegionInfo'
import Disclaimer from '../components/Disclaimer'

const Ig11Page = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { sectionId } = pageContext
  const { content, footerContent } = data

  const params = getQueryParams()
  const [reg, setReg] = useQueryParamString('reg', params.reg ?? 'GBL')
  const isInc = INCOME_GROUPS.includes(reg)
  const isUni = UNI_REGIONS.includes(reg)
  const isGbl = reg === 'GBL' || !reg
  const regionLabel = isGbl ? t('global') : t(LOCATION_LABELS[reg])
  return (
    <>
      <PageHeader
        sectionId={sectionId}
        title={content.title}
        eyebrowText={content.eyebrowText}
      />
      {/* <Header content={content} /> */}
      <PageContent>
        <SelectRegion
          reg={reg}
          setReg={setReg}
          whoOptions={WHO_LOCATION_OPTIONS}
          incOptions={INC_LOCATION_OPTIONS}
          uniOptions={UNI_LOCATION_OPTIONS}
        />
        <ChartTitle>{`${content.chartTitle}—${regionLabel}`}</ChartTitle>
        <IG11ChartWrapper
          regions={data.regions.nodes.filter(
            (d) => d.CODE === REGION_LOOKUP[reg]
          )}
          // regions={
          //   isGbl
          //     ? data.global
          //     : isInc
          //     ? data.income.nodes.find((d) => d.incomeGroup === reg)
          //     : isUni
          //     ? data.unicef.nodes.find((d) => d.uNICEFRegion === reg)
          //     : data.regions.nodes.find((d) => d.iSO3 === reg)
          // }
          content={content}
          regional={reg !== 'GBL'} // if regional, callout will not show target indicator
          title={`${content.chartTitle}—${regionLabel}`} // for chart download
        />
        <IG11Table
          regions={data.regions.nodes.filter(
            (d) => d.CODE === REGION_LOOKUP[reg]
          )}
          // regions={
          //   isGbl
          //     ? data.global
          //     : isInc
          //     ? data.income.nodes.find((d) => d.incomeGroup === reg)
          //     : isUni
          //     ? data.unicef.nodes.find((d) => d.uNICEFRegion === reg)
          //     : data.regions.nodes.find((d) => d.iSO3 === reg)
          // }
        />
        {!isGbl && (isUni || isInc) && <Disclaimer />}
        {!isGbl && <RegionInfo reg={reg} />}
      </PageContent>

      <Notes content={content} />
      <Footer content={footerContent} />
    </>
  )
}

export default Ig11Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    content: googleIg11CmsSheet(language: { eq: $language }) {
      id
      language
      eyebrowText
      title
      intro1Heading
      intro1Text
      intro2Heading
      intro2Text
      chartTitle
      calloutRt
      definitionNotesRt
      measurementNotesRt
      calculationNotesRt
      dataSourceNotesRt
    }

    footerContent: googleFooterCmsSheet(language: { eq: $language }) {
      citationRt
      descriptionRt
    }
    regions: allRegions2(filter: { IA2030_IND: { eq: "IG1.1" } }) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        INDCODE
        VALUE_NUM
        DIMENSION2
      }
    }
  }
`
// global: googleIg11Sheet(regionType: { eq: "GBL" }) {
//   id
//   memberState
//   iSO3
//   totalDeathAvert2019
//   totalDeathAvert2020
//   totalDeathAvert2021
//   totalDeathAvert2022
//   totalDeathAvert2023
//   totalDeathAvert2024
//   totalDeathAvert2025
//   totalDeathAvert2026
//   totalDeathAvert2027
//   totalDeathAvert2028
//   totalDeathAvert2029
//   hibAvert2019
//   hibAvert2020
//   hibAvert2021
//   hibAvert2022
//   hibAvert2023
//   hibAvert2024
//   hibAvert2025
//   hibAvert2026
//   hibAvert2027
//   hibAvert2028
//   hibAvert2029
//   hibAvert2030
//   jEAvert2019
//   jEAvert2020
//   jEAvert2021
//   jEAvert2022
//   jEAvert2023
//   jEAvert2024
//   jEAvert2025
//   jEAvert2026
//   jEAvert2027
//   jEAvert2028
//   jEAvert2029
//   jEAvert2030
//   measlesAvert2019
//   measlesAvert2020
//   measlesAvert2021
//   measlesAvert2022
//   measlesAvert2023
//   measlesAvert2024
//   measlesAvert2025
//   measlesAvert2026
//   measlesAvert2027
//   measlesAvert2028
//   measlesAvert2029
//   measlesAvert2030
//   menAAvert2019
//   menAAvert2020
//   menAAvert2021
//   menAAvert2022
//   menAAvert2023
//   menAAvert2024
//   menAAvert2025
//   menAAvert2026
//   menAAvert2027
//   menAAvert2028
//   menAAvert2029
//   menAAvert2030
//   pcVAvert2019
//   pcVAvert2020
//   pcVAvert2021
//   pcVAvert2022
//   pcVAvert2023
//   pcVAvert2024
//   pcVAvert2025
//   pcVAvert2026
//   pcVAvert2027
//   pcVAvert2028
//   pcVAvert2029
//   pcVAvert2030
//   pertussisAvert2019
//   pertussisAvert2020
//   pertussisAvert2021
//   pertussisAvert2022
//   pertussisAvert2023
//   pertussisAvert2024
//   pertussisAvert2025
//   pertussisAvert2026
//   pertussisAvert2027
//   pertussisAvert2028
//   pertussisAvert2029
//   pertussisAvert2030
//   rotaAvert2019
//   rotaAvert2020
//   rotaAvert2021
//   rotaAvert2022
//   rotaAvert2023
//   rotaAvert2024
//   rotaAvert2025
//   rotaAvert2026
//   rotaAvert2027
//   rotaAvert2028
//   rotaAvert2029
//   rotaAvert2030
//   rubellaAvert2019
//   rubellaAvert2020
//   rubellaAvert2021
//   rubellaAvert2022
//   rubellaAvert2023
//   rubellaAvert2024
//   rubellaAvert2025
//   rubellaAvert2026
//   rubellaAvert2027
//   rubellaAvert2028
//   rubellaAvert2029
//   rubellaAvert2030
//   tBAvert2019
//   tBAvert2020
//   tBAvert2021
//   tBAvert2022
//   tBAvert2023
//   tBAvert2024
//   tBAvert2025
//   tBAvert2026
//   tBAvert2027
//   tBAvert2028
//   tBAvert2029
//   tBAvert2030
//   tetanusAvert2019
//   tetanusAvert2020
//   tetanusAvert2021
//   tetanusAvert2022
//   tetanusAvert2023
//   tetanusAvert2024
//   tetanusAvert2025
//   tetanusAvert2026
//   tetanusAvert2027
//   tetanusAvert2028
//   tetanusAvert2029
//   tetanusAvert2030
//   yFAvert2019
//   yFAvert2020
//   yFAvert2021
//   yFAvert2022
//   yFAvert2023
//   yFAvert2024
//   yFAvert2025
//   yFAvert2026
//   yFAvert2027
//   yFAvert2028
//   yFAvert2029
//   yFAvert2030
//   diphtheriaAvert2019
//   diphtheriaAvert2020
//   diphtheriaAvert2021
//   diphtheriaAvert2022
//   diphtheriaAvert2023
//   diphtheriaAvert2024
//   diphtheriaAvert2025
//   diphtheriaAvert2026
//   diphtheriaAvert2027
//   diphtheriaAvert2028
//   diphtheriaAvert2029
//   diphtheriaAvert2030
//   hPVAvert2019
//   hPVAvert2020
//   hPVAvert2021
//   hPVAvert2022
//   hPVAvert2023
//   hPVAvert2024
//   hPVAvert2025
//   hPVAvert2026
//   hPVAvert2027
//   hPVAvert2028
//   hPVAvert2029
//   hPVAvert2030
//   hepBAvert2019
//   hepBAvert2020
//   hepBAvert2021
//   hepBAvert2022
//   hepBAvert2023
//   hepBAvert2024
//   hepBAvert2025
//   hepBAvert2026
//   hepBAvert2027
//   hepBAvert2028
//   hepBAvert2029
//   hepBAvert2030
// }
// regions: allGoogleIg11Sheet(filter: { regionType: { eq: "REG" } }) {
//   nodes {
//     id
//     memberState
//     iSO3
//     totalDeathAvert2019
//     totalDeathAvert2020
//     totalDeathAvert2021
//     totalDeathAvert2022
//     totalDeathAvert2023
//     totalDeathAvert2024
//     totalDeathAvert2025
//     totalDeathAvert2026
//     totalDeathAvert2027
//     totalDeathAvert2028
//     totalDeathAvert2029
//     hibAvert2019
//     hibAvert2020
//     hibAvert2021
//     hibAvert2022
//     hibAvert2023
//     hibAvert2024
//     hibAvert2025
//     hibAvert2026
//     hibAvert2027
//     hibAvert2028
//     hibAvert2029
//     hibAvert2030
//     jEAvert2019
//     jEAvert2020
//     jEAvert2021
//     jEAvert2022
//     jEAvert2023
//     jEAvert2024
//     jEAvert2025
//     jEAvert2026
//     jEAvert2027
//     jEAvert2028
//     jEAvert2029
//     jEAvert2030
//     measlesAvert2019
//     measlesAvert2020
//     measlesAvert2021
//     measlesAvert2022
//     measlesAvert2023
//     measlesAvert2024
//     measlesAvert2025
//     measlesAvert2026
//     measlesAvert2027
//     measlesAvert2028
//     measlesAvert2029
//     measlesAvert2030
//     menAAvert2019
//     menAAvert2020
//     menAAvert2021
//     menAAvert2022
//     menAAvert2023
//     menAAvert2024
//     menAAvert2025
//     menAAvert2026
//     menAAvert2027
//     menAAvert2028
//     menAAvert2029
//     menAAvert2030
//     pcVAvert2019
//     pcVAvert2020
//     pcVAvert2021
//     pcVAvert2022
//     pcVAvert2023
//     pcVAvert2024
//     pcVAvert2025
//     pcVAvert2026
//     pcVAvert2027
//     pcVAvert2028
//     pcVAvert2029
//     pcVAvert2030
//     pertussisAvert2019
//     pertussisAvert2020
//     pertussisAvert2021
//     pertussisAvert2022
//     pertussisAvert2023
//     pertussisAvert2024
//     pertussisAvert2025
//     pertussisAvert2026
//     pertussisAvert2027
//     pertussisAvert2028
//     pertussisAvert2029
//     pertussisAvert2030
//     rotaAvert2019
//     rotaAvert2020
//     rotaAvert2021
//     rotaAvert2022
//     rotaAvert2023
//     rotaAvert2024
//     rotaAvert2025
//     rotaAvert2026
//     rotaAvert2027
//     rotaAvert2028
//     rotaAvert2029
//     rotaAvert2030
//     rubellaAvert2019
//     rubellaAvert2020
//     rubellaAvert2021
//     rubellaAvert2022
//     rubellaAvert2023
//     rubellaAvert2024
//     rubellaAvert2025
//     rubellaAvert2026
//     rubellaAvert2027
//     rubellaAvert2028
//     rubellaAvert2029
//     rubellaAvert2030
//     tBAvert2019
//     tBAvert2020
//     tBAvert2021
//     tBAvert2022
//     tBAvert2023
//     tBAvert2024
//     tBAvert2025
//     tBAvert2026
//     tBAvert2027
//     tBAvert2028
//     tBAvert2029
//     tBAvert2030
//     tetanusAvert2019
//     tetanusAvert2020
//     tetanusAvert2021
//     tetanusAvert2022
//     tetanusAvert2023
//     tetanusAvert2024
//     tetanusAvert2025
//     tetanusAvert2026
//     tetanusAvert2027
//     tetanusAvert2028
//     tetanusAvert2029
//     tetanusAvert2030
//     yFAvert2019
//     yFAvert2020
//     yFAvert2021
//     yFAvert2022
//     yFAvert2023
//     yFAvert2024
//     yFAvert2025
//     yFAvert2026
//     yFAvert2027
//     yFAvert2028
//     yFAvert2029
//     yFAvert2030
//     diphtheriaAvert2019
//     diphtheriaAvert2020
//     diphtheriaAvert2021
//     diphtheriaAvert2022
//     diphtheriaAvert2023
//     diphtheriaAvert2024
//     diphtheriaAvert2025
//     diphtheriaAvert2026
//     diphtheriaAvert2027
//     diphtheriaAvert2028
//     diphtheriaAvert2029
//     diphtheriaAvert2030
//     hPVAvert2019
//     hPVAvert2020
//     hPVAvert2021
//     hPVAvert2022
//     hPVAvert2023
//     hPVAvert2024
//     hPVAvert2025
//     hPVAvert2026
//     hPVAvert2027
//     hPVAvert2028
//     hPVAvert2029
//     hPVAvert2030
//     hepBAvert2019
//     hepBAvert2020
//     hepBAvert2021
//     hepBAvert2022
//     hepBAvert2023
//     hepBAvert2024
//     hepBAvert2025
//     hepBAvert2026
//     hepBAvert2027
//     hepBAvert2028
//     hepBAvert2029
//     hepBAvert2030
//   }
// }
// income: allGoogleIg11Sheet(filter: { regionType: { eq: "INC" } }) {
//   nodes {
//     id
//     memberState
//     incomeGroup
//     totalDeathAvert2019
//     totalDeathAvert2020
//     totalDeathAvert2021
//     totalDeathAvert2022
//     totalDeathAvert2023
//     totalDeathAvert2024
//     totalDeathAvert2025
//     totalDeathAvert2026
//     totalDeathAvert2027
//     totalDeathAvert2028
//     totalDeathAvert2029
//     hibAvert2019
//     hibAvert2020
//     hibAvert2021
//     hibAvert2022
//     hibAvert2023
//     hibAvert2024
//     hibAvert2025
//     hibAvert2026
//     hibAvert2027
//     hibAvert2028
//     hibAvert2029
//     hibAvert2030
//     jEAvert2019
//     jEAvert2020
//     jEAvert2021
//     jEAvert2022
//     jEAvert2023
//     jEAvert2024
//     jEAvert2025
//     jEAvert2026
//     jEAvert2027
//     jEAvert2028
//     jEAvert2029
//     jEAvert2030
//     measlesAvert2019
//     measlesAvert2020
//     measlesAvert2021
//     measlesAvert2022
//     measlesAvert2023
//     measlesAvert2024
//     measlesAvert2025
//     measlesAvert2026
//     measlesAvert2027
//     measlesAvert2028
//     measlesAvert2029
//     measlesAvert2030
//     menAAvert2019
//     menAAvert2020
//     menAAvert2021
//     menAAvert2022
//     menAAvert2023
//     menAAvert2024
//     menAAvert2025
//     menAAvert2026
//     menAAvert2027
//     menAAvert2028
//     menAAvert2029
//     menAAvert2030
//     pcVAvert2019
//     pcVAvert2020
//     pcVAvert2021
//     pcVAvert2022
//     pcVAvert2023
//     pcVAvert2024
//     pcVAvert2025
//     pcVAvert2026
//     pcVAvert2027
//     pcVAvert2028
//     pcVAvert2029
//     pcVAvert2030
//     pertussisAvert2019
//     pertussisAvert2020
//     pertussisAvert2021
//     pertussisAvert2022
//     pertussisAvert2023
//     pertussisAvert2024
//     pertussisAvert2025
//     pertussisAvert2026
//     pertussisAvert2027
//     pertussisAvert2028
//     pertussisAvert2029
//     pertussisAvert2030
//     rotaAvert2019
//     rotaAvert2020
//     rotaAvert2021
//     rotaAvert2022
//     rotaAvert2023
//     rotaAvert2024
//     rotaAvert2025
//     rotaAvert2026
//     rotaAvert2027
//     rotaAvert2028
//     rotaAvert2029
//     rotaAvert2030
//     rubellaAvert2019
//     rubellaAvert2020
//     rubellaAvert2021
//     rubellaAvert2022
//     rubellaAvert2023
//     rubellaAvert2024
//     rubellaAvert2025
//     rubellaAvert2026
//     rubellaAvert2027
//     rubellaAvert2028
//     rubellaAvert2029
//     rubellaAvert2030
//     tBAvert2019
//     tBAvert2020
//     tBAvert2021
//     tBAvert2022
//     tBAvert2023
//     tBAvert2024
//     tBAvert2025
//     tBAvert2026
//     tBAvert2027
//     tBAvert2028
//     tBAvert2029
//     tBAvert2030
//     tetanusAvert2019
//     tetanusAvert2020
//     tetanusAvert2021
//     tetanusAvert2022
//     tetanusAvert2023
//     tetanusAvert2024
//     tetanusAvert2025
//     tetanusAvert2026
//     tetanusAvert2027
//     tetanusAvert2028
//     tetanusAvert2029
//     tetanusAvert2030
//     yFAvert2019
//     yFAvert2020
//     yFAvert2021
//     yFAvert2022
//     yFAvert2023
//     yFAvert2024
//     yFAvert2025
//     yFAvert2026
//     yFAvert2027
//     yFAvert2028
//     yFAvert2029
//     yFAvert2030
//     diphtheriaAvert2019
//     diphtheriaAvert2020
//     diphtheriaAvert2021
//     diphtheriaAvert2022
//     diphtheriaAvert2023
//     diphtheriaAvert2024
//     diphtheriaAvert2025
//     diphtheriaAvert2026
//     diphtheriaAvert2027
//     diphtheriaAvert2028
//     diphtheriaAvert2029
//     diphtheriaAvert2030
//     hPVAvert2019
//     hPVAvert2020
//     hPVAvert2021
//     hPVAvert2022
//     hPVAvert2023
//     hPVAvert2024
//     hPVAvert2025
//     hPVAvert2026
//     hPVAvert2027
//     hPVAvert2028
//     hPVAvert2029
//     hPVAvert2030
//     hepBAvert2019
//     hepBAvert2020
//     hepBAvert2021
//     hepBAvert2022
//     hepBAvert2023
//     hepBAvert2024
//     hepBAvert2025
//     hepBAvert2026
//     hepBAvert2027
//     hepBAvert2028
//     hepBAvert2029
//     hepBAvert2030
//   }
// }
// unicef: allGoogleIg11Sheet(filter: { regionType: { eq: "UNI" } }) {
//   nodes {
//     id
//     memberState
//     uNICEFRegion
//     totalDeathAvert2019
//     totalDeathAvert2020
//     totalDeathAvert2021
//     totalDeathAvert2022
//     totalDeathAvert2023
//     totalDeathAvert2024
//     totalDeathAvert2025
//     totalDeathAvert2026
//     totalDeathAvert2027
//     totalDeathAvert2028
//     totalDeathAvert2029
//     hibAvert2019
//     hibAvert2020
//     hibAvert2021
//     hibAvert2022
//     hibAvert2023
//     hibAvert2024
//     hibAvert2025
//     hibAvert2026
//     hibAvert2027
//     hibAvert2028
//     hibAvert2029
//     hibAvert2030
//     jEAvert2019
//     jEAvert2020
//     jEAvert2021
//     jEAvert2022
//     jEAvert2023
//     jEAvert2024
//     jEAvert2025
//     jEAvert2026
//     jEAvert2027
//     jEAvert2028
//     jEAvert2029
//     jEAvert2030
//     measlesAvert2019
//     measlesAvert2020
//     measlesAvert2021
//     measlesAvert2022
//     measlesAvert2023
//     measlesAvert2024
//     measlesAvert2025
//     measlesAvert2026
//     measlesAvert2027
//     measlesAvert2028
//     measlesAvert2029
//     measlesAvert2030
//     menAAvert2019
//     menAAvert2020
//     menAAvert2021
//     menAAvert2022
//     menAAvert2023
//     menAAvert2024
//     menAAvert2025
//     menAAvert2026
//     menAAvert2027
//     menAAvert2028
//     menAAvert2029
//     menAAvert2030
//     pcVAvert2019
//     pcVAvert2020
//     pcVAvert2021
//     pcVAvert2022
//     pcVAvert2023
//     pcVAvert2024
//     pcVAvert2025
//     pcVAvert2026
//     pcVAvert2027
//     pcVAvert2028
//     pcVAvert2029
//     pcVAvert2030
//     pertussisAvert2019
//     pertussisAvert2020
//     pertussisAvert2021
//     pertussisAvert2022
//     pertussisAvert2023
//     pertussisAvert2024
//     pertussisAvert2025
//     pertussisAvert2026
//     pertussisAvert2027
//     pertussisAvert2028
//     pertussisAvert2029
//     pertussisAvert2030
//     rotaAvert2019
//     rotaAvert2020
//     rotaAvert2021
//     rotaAvert2022
//     rotaAvert2023
//     rotaAvert2024
//     rotaAvert2025
//     rotaAvert2026
//     rotaAvert2027
//     rotaAvert2028
//     rotaAvert2029
//     rotaAvert2030
//     rubellaAvert2019
//     rubellaAvert2020
//     rubellaAvert2021
//     rubellaAvert2022
//     rubellaAvert2023
//     rubellaAvert2024
//     rubellaAvert2025
//     rubellaAvert2026
//     rubellaAvert2027
//     rubellaAvert2028
//     rubellaAvert2029
//     rubellaAvert2030
//     tBAvert2019
//     tBAvert2020
//     tBAvert2021
//     tBAvert2022
//     tBAvert2023
//     tBAvert2024
//     tBAvert2025
//     tBAvert2026
//     tBAvert2027
//     tBAvert2028
//     tBAvert2029
//     tBAvert2030
//     tetanusAvert2019
//     tetanusAvert2020
//     tetanusAvert2021
//     tetanusAvert2022
//     tetanusAvert2023
//     tetanusAvert2024
//     tetanusAvert2025
//     tetanusAvert2026
//     tetanusAvert2027
//     tetanusAvert2028
//     tetanusAvert2029
//     tetanusAvert2030
//     yFAvert2019
//     yFAvert2020
//     yFAvert2021
//     yFAvert2022
//     yFAvert2023
//     yFAvert2024
//     yFAvert2025
//     yFAvert2026
//     yFAvert2027
//     yFAvert2028
//     yFAvert2029
//     yFAvert2030
//     diphtheriaAvert2019
//     diphtheriaAvert2020
//     diphtheriaAvert2021
//     diphtheriaAvert2022
//     diphtheriaAvert2023
//     diphtheriaAvert2024
//     diphtheriaAvert2025
//     diphtheriaAvert2026
//     diphtheriaAvert2027
//     diphtheriaAvert2028
//     diphtheriaAvert2029
//     diphtheriaAvert2030
//     hPVAvert2019
//     hPVAvert2020
//     hPVAvert2021
//     hPVAvert2022
//     hPVAvert2023
//     hPVAvert2024
//     hPVAvert2025
//     hPVAvert2026
//     hPVAvert2027
//     hPVAvert2028
//     hPVAvert2029
//     hPVAvert2030
//     hepBAvert2019
//     hepBAvert2020
//     hepBAvert2021
//     hepBAvert2022
//     hepBAvert2023
//     hepBAvert2024
//     hepBAvert2025
//     hepBAvert2026
//     hepBAvert2027
//     hepBAvert2028
//     hepBAvert2029
//     hepBAvert2030
//   }
// }
