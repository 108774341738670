import * as React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { useChartDimensions } from '../../hooks/dimensions'
import ChartProvider from '../ChartContext'
import { MediaQueries as Mq } from '../../styles/MediaQueries'
import Callout from '../Callout'
import IG11Chart from './IG11Chart'
import { NUM_YEARS, PRINT_DIMENSIONS } from '../../utils/const'
import ImageExport from '../ImageExport'
import { useTranslation } from 'react-i18next'
import { group } from 'd3-array'

const Figure = styled.figure`
  flex: 1 1;
  width: 75%;
  height: 65vh;
`
const Flex = styled.div`
  display: flex;
  // justify-content: space-between;
  margin-top: 3rem;
`
const Container = styled.div`
  margin-bottom: 2rem;
  ${Mq.md} {
    margin-bottom: 9rem;
  }
`

const IG11ChartWrapper = ({ regions, content, regional, title }) => {
  const { t } = useTranslation()
  const [ref, dimensions] = useChartDimensions({
    marginTop: 16,
    marginRight: 8,
    marginBottom: 120,
    marginLeft: 32
  })
  const dataMap = group(regions, (d) => d.YEAR)
  const groupedByYear = Array.from(dataMap.values())
  const formattedData = groupedByYear.map((item) => {
    return item.reduce((acc, d) => {
      if (d.DIMENSION2 !== null) {
        acc[d.DIMENSION2] = d.VALUE_NUM
        acc['year'] = d.YEAR
      }
      return acc
    }, {})
  })

  const totalsByYear = groupedByYear.map((array) => {
    return array.find((d) => d.DIMENSION2 === null) // this is the total row
  })

  const total = groupedByYear
    .map((array) => {
      return array.find((d) => d.DIMENSION2 === null) // this is the total row
    })
    .reduce((sum, d) => {
      //take out baseline data from total
      sum += d.YEAR === 2019 ? 0 : d.VALUE_NUM
      return sum
    }, 0)

  //data wrangling
  // const wrangleData = (data) => {
  //   let maxYearWithData
  //   const allCategories = [],
  //     allYears = []
  //   const allData = Object.entries(regions)
  //     .map(([key, value]) => {
  //       const keysToExclude = [
  //         'memberState',
  //         'iSO3',
  //         'id',
  //         'incomeGroup',
  //         'uNICEFRegion',
  //         'totalDeathAvert2019',
  //         'totalDeathAvert2020',
  //         'totalDeathAvert2021',
  //         'totalDeathAvert2022',
  //         'totalDeathAvert2023',
  //         'totalDeathAvert2024',
  //         'totalDeathAvert2025',
  //         'totalDeathAvert2026',
  //         'totalDeathAvert2027',
  //         'totalDeathAvert2028',
  //         'totalDeathAvert2029',
  //         'totalDeathAvert2030'
  //       ]

  //       const year = +key.slice(-4)
  //       //get the highest year with a value
  //       if (key.startsWith('total') && value !== null) {
  //         maxYearWithData = year
  //       }
  //       if (!keysToExclude.includes(key)) {
  //         const antigen = key.split('A')[0]
  //         allCategories.push(antigen)
  //         allYears.push(year)
  //         return { year, antigen, value }
  //       } else return null
  //     })
  //     .filter((item) => item)

  //   //reduce allData to collect all categories for one year in single object
  //   const years = Array.from(new Set(allYears))
  //   const categories = Array.from(new Set(allCategories))

  //   const formattedData = years.map((year) => {
  //     const yearData = allData
  //       .filter((d) => d.year === year)
  //       .reduce((acc, d) => {
  //         acc[d.antigen] = d.value < 0 ? 0 : d.value
  //         return acc
  //       }, {})

  //     return Object.assign(
  //       {
  //         year
  //       },
  //       yearData
  //     )
  //   })

  //  return Object.assign(formattedData, { categories, years, maxYearWithData })
  // }

  //this gives correct format for stacked bar chart -- does not have totals category
  //const data = wrangleData(regions)
  //this makes lookup object for totals at top of bars
  // const totals = data.years
  //   .map((year) => {
  //     return Object.entries(regions)
  //       .map(([key, value]) => {
  //         if (key.startsWith('total')) var yearInKey = +key.slice(-4)
  //         if (year === yearInKey) {
  //           return { year: yearInKey, total: value }
  //         } else return null
  //       })
  //       .filter((item) => item)
  //   })
  //   .flat()
  // const cumulative = totals.reduce(
  //   (acc, d) => {
  //take out baseline data
  //     if (d.year !== 2019) {
  //       acc['total'] += d.total
  //       acc['numYears'] += d.total ? 1 : 0 //count the years with data
  //     }
  //     return acc
  //   },
  //   { total: 0, numYears: 0 }
  // )

  //don't count first year in number of elapsed years
  const numYears = formattedData.length - 1
  const goal = 50000000
  const goalPerYear = goal / NUM_YEARS
  const TotalNeededToBeOnTrack = numYears * goalPerYear
  const onTarget = total >= TotalNeededToBeOnTrack

  const matches = useMediaQuery({ query: '(max-width: 900px)' })
  return (
    <>
      <ImageExport title={title} dataSource={content.dataSourceNotesRt}>
        <Flex>
          {/* <Figure
            //ref={ref}
            role="img"
            alt="Stacked bar chart of number of future deaths averted through immunization each year divided up by vaccine with the goal of reaching 5.8 million deaths averted globally."
          > */}
          <ChartProvider dimensions={PRINT_DIMENSIONS}>
            <IG11Chart
              formattedData={formattedData}
              totals={totalsByYear}
              regional={regional}
              print={true}
            />
          </ChartProvider>
          {/* </Figure> */}

          <Callout
            content={content}
            onTarget={onTarget}
            regional={regional}
            currYear={formattedData[formattedData.length - 1].year}
          />
        </Flex>
      </ImageExport>

      <Container>
        <Flex>
          <Figure
            ref={ref}
            role="img"
            alt={t(
              'Stacked bar chart of number of future deaths averted through immunization each year divided up by vaccine with the goal of reaching 5.8 million deaths averted globally.'
            )}
          >
            <ChartProvider dimensions={dimensions}>
              <IG11Chart
                formattedData={formattedData}
                totals={totalsByYear}
                regional={regional}
              />
            </ChartProvider>
          </Figure>
          {!matches ? (
            <Callout
              content={content}
              onTarget={onTarget}
              regional={regional}
              currYear={formattedData[formattedData.length - 1].year}
            />
          ) : null}
        </Flex>
        {matches ? (
          <Callout
            content={content}
            onTarget={onTarget}
            regional={regional}
            currYear={formattedData[formattedData.length - 1].year}
          />
        ) : null}
      </Container>
    </>
  )
}
export default IG11ChartWrapper
